import { GetISOStringSwedenTime } from './getISOStringSwedenTime';

export const GetMonthYearSearchSpan = async (todayDate, interval = 'month') => {
    const dateObj = new Date(todayDate);
    let firstDate;
    let lastDate;

    // Saves the first date
    if (interval === 'month') {
        firstDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
    }
    else if (interval === 'year') {
        firstDate = new Date(dateObj.getFullYear(), 0);
    }
    firstDate = await GetISOStringSwedenTime(firstDate);
    //console.log('firstDate', firstDate);

    // Saves the last day
    if (interval === 'month') {
        lastDate = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 1);
    }
    else if (interval === 'year') {
        lastDate = new Date(dateObj.getFullYear() + 1, 0);
    }
    lastDate = await GetISOStringSwedenTime(lastDate, 5);
    //console.log('lastDate', lastDate);

    return [firstDate, lastDate]
}