import { GetISOStringSwedenTime } from '../Helpers/getISOStringSwedenTime';
import { GetDateTimeNow } from '../Helpers/getDateTimeNow';
import { GetNordpoolData } from './api-requests';

export const GetCostJustNow = async () => {
    let dateTime = await GetISOStringSwedenTime(await GetDateTimeNow());
    const data = await GetNordpoolData(dateTime.split('T')[0]);
    let today = data[0].filter(obj => {
        return obj.startTime + '.000Z' < dateTime && obj.endTime + '.000Z' > dateTime
    })
    return today
}