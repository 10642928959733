/* eslint-disable */
import { GetUsedWattFromAllEmetersBetweenDates } from './api-requests';
import { GetDateTimeNow } from '../Helpers/getDateTimeNow';
import { GetISOStringSwedenTime } from '../Helpers/getISOStringSwedenTime';
import { GetMonthYearSearchSpan } from '../Helpers/getMonthYearSearchSpan';

export const getWattForDayMonthOrYear = async (activeDate, intervall = 'month') => {
    const dateObj = new Date(activeDate);

    if (intervall === 'day') {

        // Gets the current date and time
        let TodayDateAndNowTime = await GetDateTimeNow();

        // Change date to next day
        const NextDateWithFirstHour = (dateString) => {
            const newDate = new Date(dateString);
            newDate.setDate(newDate.getDate() + 1);
            const nextDay = newDate.toISOString().split('T')[0];

            return nextDay
        }

        // Change the time to 23:59:59
        const DateWithLastHour = (date) => {
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return date;
        }

        if (DateWithLastHour(dateObj) < TodayDateAndNowTime) {
            let totalWattToday = await GetUsedWattFromAllEmetersBetweenDates(`${activeDate}T00:00:00.000Z`, `${NextDateWithFirstHour(activeDate)}T00:05:00.000Z`);
            return totalWattToday[0]
        } else {
            let totalWattToday = await GetUsedWattFromAllEmetersBetweenDates(`${activeDate}T00:00:00.000Z`, await GetISOStringSwedenTime(TodayDateAndNowTime));
            return totalWattToday[0]
        }
    }

    if (intervall === 'month') {

        const dates = await GetMonthYearSearchSpan(activeDate, 'month');

        // Get total from all three emeters at whole hour from neo4j
        let totalWatt = await GetUsedWattFromAllEmetersBetweenDates(dates[0], dates[1]);

        // return totalWatt[0]
        return totalWatt[0]
    }

    if (intervall === 'year') {

        // Saves the first date
        let firstDate = new Date(dateObj.getFullYear(), 0);
        firstDate = await GetISOStringSwedenTime(firstDate);

        // Saves the last day
        let lastDate = new Date(dateObj.getFullYear()+1, + 0);
        lastDate = await GetISOStringSwedenTime(lastDate, 5);

        // Get total from all three emeters at whole hour from neo4j
        let totalWatt = await GetUsedWattFromAllEmetersBetweenDates(firstDate, lastDate);

        // return totalWatt[0]
        return totalWatt[0]
    }
}